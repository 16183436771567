<div class="modal-window">
  <div class="modal-window-header">
    <h3 class="modal-window-title">
      {{ data.title | translate }}
    </h3>
    <button type="button"
            (click)="close()">X</button>
  </div>
  <div class="modal-window-body">
    <div class="modal-window-content" *ngIf="data.content">
      <div class="content">{{ data.content | translate }}</div>
    </div>
    <div class="form-group checkbox-list" *ngIf="data.list?.length">
      <label class="custom-checkbox select-all">
        <input
          type="checkbox"
          [checked]="selectAllChecked()"
          (change)="onSelectAllChange($event.target.checked)">
        <span>{{ 'SHARED.SELECT_ALL_JOBS' | translate }}</span>
        <span class="checkmark square"></span>
      </label>
      <div *ngFor="let item of data.list; let i = index">
        <label class="custom-checkbox">
        <input
          [value]="item.id"
          type="checkbox"
          [checked]="item.checked"
          (change)="onChecked($event.target.checked, i)">
        <span>{{item.jobTitle}}</span>
        <span>{{ 'SHARED.LOCATION' | translate }}: {{ item.jobLocation.name[item.company.language] | jobLocation: true }}</span>
        <span class="checkmark square"></span>
      </label>
      </div>
    </div>
    <div class="modal-window-content ql-editor" *ngIf="data.htmlContent">
      <div class="content" [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.htmlContent)"></div>
    </div>
  </div>
  <div class="modal-footer-content">
    <button (click)="close()"
            *ngIf="!data.hideCancelButton"
            class="btn-secondary">{{ data.cancelBtnTitle | translate }}</button>
    <button (click)="confirm()"
            *ngIf="!data.hideConfirmButton"
            class="btn-primary">{{ data.confirmBtnTitle | translate }}</button>
  </div>
</div>

<div class="modal-window">
  <div class="modal-window-header">
    <h3 class="modal-window-title">
      {{ data.title | translate }}
    </h3>
    <button type="button"
            (click)="close()">X</button>
  </div>
  <div class="modal-window-body">
    <re-captcha class="captcha"
                (resolved)="resolved($event)"
                siteKey="6LfsntMmAAAAAJgRSFQ_iwioVOXdNxTB70ezaOtL">
    </re-captcha>
  </div>
</div>

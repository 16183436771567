import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractModal } from '../abstract-modal';
import { ModalService } from '../../../services/modal.service';
import { CaptchaModal } from '../../../classes/modal.class';
import { CaptchaModalData } from 'src/app/models/modal.interface';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './captcha-modal.component.html',
  styleUrls: ['./captcha-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaptchaModalComponent extends AbstractModal implements OnInit {

  modal: CaptchaModal;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    modalService: ModalService
  ) {
    super(modalService);
  }

  get data(): CaptchaModalData {
    return this.modal.data;
  }

  ngOnInit(): void {
    this.modalService.modalClosed$
    .pipe(
      takeUntil(this._ngUnsubscribe$)
    )
    .subscribe((modal) => {
      if (this.modal === modal) {
        this._ngUnsubscribe$.next();
        this._ngUnsubscribe$.complete();
        this.data.close();
      }
    })
  }

  resolved(result: any): void {
    this.data.confirm(result);
    this.closeModal();
  }

  close(): void {
    this.data.close();
    this.closeModal();
  }
}
